
import AuthMixin from '@/mixins/AuthMixin';
import { handleSuccessUI, handleErrorUI, checkFieldErrors } from '@/util/error';
import LoginTabs from '@/components/Auth/Tabs.vue';

const createErrors = () => ({
  newPassword: '',
  confirmPassword: ''
});

export default {
  components: { LoginTabs },
  mixins: [AuthMixin],
  data() {
    return {
      token: '',
      tenant: '',
      email: '',
      isHide: false,
      newPassword: '',
      confirmPassword: '',
      verificationCode: '',
      i18n: {
        resetPassword: 'Reset Password',
        newPassword: 'New Password',
        confirmPassword: 'Confirm New Password',
        passwordHint: 'Type password',
        passwordConfirmHint: 'Type password confirmation',
        submit: 'Submit'
      },
      errors: createErrors()
    };
  },
  mounted() {
    const token = this.$route.params.token;
    const tenant = this.$route.params.tenant;
    const email = this.$router.currentRoute.query.email;
    const code = this.$router.currentRoute.query.code;
    if (!email) {
      this.$router.push({ name: 'auth:login' }).catch(_ => {});
    }
    // if (!token || !tenant) {
    //   this.$router.push({ name: 'auth:login' }).catch(_ => {});
    //   return;
    // }
    this.token = token as string;
    this.tenant = tenant as string;
    this.verificationCode = code as string;
    this.email = email as string;
  },
  methods: {
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    togglePassword() {
      this.isHide = !this.isHide;
    },
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    validate() {
      const errors = createErrors();
      if (this.newPassword.length <= 0)
        errors.newPassword = this.$t('AuthPage.field_is_required');
      if (this.confirmPassword.length <= 0)
        errors.confirmPassword = this.$t('AuthPage.field_is_required');
      else if (this.newPassword !== this.confirmPassword)
        errors.confirmPassword = "Password confirmation doesn't match";
      this.errors = errors;
      return checkFieldErrors(errors);
    },
    async handleSubmit() {
      try {
        await this.$repo.auth.forgetPassword(
          this.verificationCode,
          this.newPassword
        );
        handleSuccessUI(this.$t('AuthPage.password_changed_successfully'));
        this.$router.push({ name: 'auth:login' }).catch(_ => {});
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
